import { COLORING_TYPES, DEFAULT_COLOR_PARAMS } from '../helper';
import Base from './Base';

export default class Background extends Base {
  constructor(element, palette) {
    super(element, palette);
    this.showTabs = true;
    this.activeGradientProperty = null;
    this.image = null;
    this.imagePosition = null;
    this.imageRepeat = null;
    this.imageVerticalAlign = null;
    this.imageHorizontalAlign = null;
    this.linearDirection = null;
    this.imageCrop = null;
  }

  getBackgroundProperties() {
    return [
      this.getColorProperty(),
      this.getColoringTypeProperty(),
      this.getColor2Property(),
      this.getImagePositionProperty(),
      this.getImageRepeatProperty(),
      this.getImageVerticalAlignProperty(),
      this.getImageHorizontalAlignProperty(),
      this.getLinearDirectionProperty(),
      this.getImageIdProperty(),
      this.getImageUrlProperty(),
      this.getImageCropProperty(),
    ];
  }

  getValues() {
    // Properties to save
    const properties = this.getBackgroundProperties();
    const values = [];
    for (const property of properties) {
      values.push({
        uid: this.element.uid,
        prop: property,
        value: this.getValueByProperty(property),
      });
    }
    return values;
  }

  getColorStyle() {
    const typeProperty = this.getColoringTypeProperty();
    const type = this.getValueByProperty(typeProperty);

    if (type === COLORING_TYPES.SOLID_FILL) {
      const color = this.getValueByProperty(this.getColorProperty());
      return `background-color: ${this.convertColorStyleValue(color)}`;
    }

    if (type === COLORING_TYPES.GRADIENT) {
      const color = this.getValueByProperty(this.getColorProperty());
      const color2 = this.getValueByProperty(this.getColor2Property());
      const direction = this.getValueByProperty(this.getLinearDirectionProperty());
      return (
        `background-image: linear-gradient(${direction}deg, ` +
        `${this.convertColorStyleValue(color)}, ${this.convertColorStyleValue(color2)})`
      );
    }

    if (type === COLORING_TYPES.IMAGE) {
      const color = this.getValueByProperty(this.getColorProperty());
      return `background: ${this.convertColorStyleValue(color)}`;
    }

    return '';
  }

  getColoringType() {
    const typeProperty = this.getColoringTypeProperty();
    const types = this.getEnabledBackgroundTypes();
    const type = this.getValueByProperty(typeProperty);
    if (Object.values(types).includes(type)) {
      return type;
    }
    return this.getDefaultColoringType();
  }

  getColor2() {
    const property = this.getColor2Property();
    return this.getValueByProperty(property);
  }

  getImageSrc() {
    return this.image?.url || this.getValueByProperty(this.getImageUrlProperty());
  }

  getImageId() {
    return this.image?.id || this.getValueByProperty(this.getImageIdProperty());
  }

  getImagePosition() {
    return this.imagePosition || this.getValueByProperty(this.getImagePositionProperty());
  }

  getImageCrop() {
    return this.imageCrop || this.getValueByProperty(this.getImageCropProperty());
  }

  getImageRepeat() {
    return this.imageRepeat || this.getValueByProperty(this.getImageRepeatProperty());
  }

  getImageHorizontalAlign() {
    const property = this.getImageHorizontalAlignProperty();
    return this.imageHorizontalAlign || this.getValueByProperty(property);
  }

  getImageVerticalAlign() {
    return this.imageVerticalAlign || this.getValueByProperty(this.getImageVerticalAlignProperty());
  }

  getLinearDirection() {
    return this.linearDirection || this.getValueByProperty(this.getLinearDirectionProperty());
  }

  getColoringTypeProperty() {
    return `${this.baseProperty}.${DEFAULT_COLOR_PARAMS.TYPE}`;
  }

  getColor2Property() {
    return `${this.baseProperty}.${DEFAULT_COLOR_PARAMS.COLOR2}`;
  }

  getLinearDirectionProperty() {
    return `${this.baseProperty}.${DEFAULT_COLOR_PARAMS.LINEAR_DIRECTION}`;
  }

  getImageIdProperty() {
    return `${this.baseProperty}.${DEFAULT_COLOR_PARAMS.IMAGE_ID}`;
  }

  getImageUrlProperty() {
    return `${this.baseProperty}.${DEFAULT_COLOR_PARAMS.IMAGE_URL}`;
  }

  getImageCropProperty() {
    return `${this.baseProperty}.${DEFAULT_COLOR_PARAMS.CROP}`;
  }

  getImagePositionProperty() {
    return `${this.baseProperty}.${DEFAULT_COLOR_PARAMS.IMAGE_POSITION}`;
  }

  getImageRepeatProperty() {
    return `${this.baseProperty}.${DEFAULT_COLOR_PARAMS.IMAGE_REPEAT}`;
  }

  getImageAlignProperty() {
    return `${this.baseProperty}.${DEFAULT_COLOR_PARAMS.IMAGE_ALIGN}`;
  }

  getImageHorizontalAlignProperty() {
    return `${this.baseProperty}.${DEFAULT_COLOR_PARAMS.IMAGE_HORIZONTAL_ALIGN}`;
  }

  getImageVerticalAlignProperty() {
    return `${this.baseProperty}.${DEFAULT_COLOR_PARAMS.IMAGE_VERTICAL_ALIGN}`;
  }

  getEnabledBackgroundTypes() {
    return COLORING_TYPES;
  }

  handleColorChange(color) {
    const type = this.getColoringType();

    if (type === COLORING_TYPES.SOLID_FILL) {
      this.setColor(color);
      this.setColoringType(COLORING_TYPES.SOLID_FILL);
    }
    if (type === COLORING_TYPES.GRADIENT) {
      this.setElementValue(
        this.activeGradientProperty || `${this.baseProperty}.${DEFAULT_COLOR_PARAMS.COLOR}`,
        color,
      );
      this.setColoringType(COLORING_TYPES.GRADIENT);
    }
    if (type === COLORING_TYPES.IMAGE) {
      this.setColor(color);
    }
  }

  setColoringType(type) {
    const property = `${this.baseProperty}.${DEFAULT_COLOR_PARAMS.TYPE}`;
    this.setElementValue(property, type);
  }

  setActiveGradientProperty(property) {
    this.activeGradientProperty = `${this.baseProperty}.${property}`;
  }

  setImage(imageId, url) {
    this.setElementValue(`${this.baseProperty}.${DEFAULT_COLOR_PARAMS.IMAGE_ID}`, imageId);
    this.setElementValue(`${this.baseProperty}.${DEFAULT_COLOR_PARAMS.IMAGE_URL}`, url);
    this.setColoringType(COLORING_TYPES.IMAGE);
    this.image = { _id: imageId, url };
  }

  setLinearDirection(value) {
    this.setElementValue(`${this.baseProperty}.${DEFAULT_COLOR_PARAMS.LINEAR_DIRECTION}`, value);
    this.linearDirection = value;
  }

  setImagePosition(value) {
    this.setElementValue(`${this.baseProperty}.${DEFAULT_COLOR_PARAMS.IMAGE_POSITION}`, value);
    this.imagePosition = value;
  }

  setImageCrop(value) {
    this.setElementValue(`${this.baseProperty}.${DEFAULT_COLOR_PARAMS.CROP}`, value);
    this.imageCrop = value;
  }

  setImageRepeat(value) {
    this.setElementValue(`${this.baseProperty}.${DEFAULT_COLOR_PARAMS.IMAGE_REPEAT}`, value);
    this.imageRepeat = value;
  }

  setImageHorizontalAlign(value) {
    this.setElementValue(
      `${this.baseProperty}.${DEFAULT_COLOR_PARAMS.IMAGE_HORIZONTAL_ALIGN}`,
      value,
    );
    this.imageHorizontalAlign = value;
  }

  setImageVerticalAlign(value) {
    this.setElementValue(
      `${this.baseProperty}.${DEFAULT_COLOR_PARAMS.IMAGE_VERTICAL_ALIGN}`,
      value,
    );
    this.imageVerticalAlign = value;
  }
}
