import Base from './Base';

export default class GlobalStyle extends Base {
  getValues() {
    const property = this.getColorProperty();

    return [
      {
        prop: property,
        value: this.getColorWithFallback(property),
      },
    ];
  }

  isGlobalStyle() {
    return true;
  }
}
